import React from "react";
import { useState } from "react";

const ProductComponent = () =>{

    // const customSection = {
    //     height: '300vh',
    // }

    // const rowSec = {
    //     display: 'flex',
    //     width:'98vw',
    // }

    const head = {
        marginTop: '5%',
        marginLeft: '10%',
        marginBottom: '5%'
    }

    // const columnTwo = {
    //     position: 'relative',
    //     width: '50%',
    //     height: '80vh',
    //     textAlign:'center',
    //     boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px',
    //     marginLeft: '10%',
    // }

    // const columnTwoRight = {
    //     position: 'relative',
    //     width: '50%',
    //     height: '80vh',
    //     textAlign:'center',
    //     marginRight: '10%',
    //     boxShadow: 'rgb(0 0 0 / 10%) 1px 0px 5px 0px',
    // }

    // const columnThreeLeft = {
    //     position: 'relative',
    //     width: '35%',
    //     height: '80vh',
    //     textAlign:'center',
    //     marginLeft: '10%',
    //     boxShadow: 'rgb(0 0 0 / 10%) 1px 0px 5px 0px',
    // }

    // const columnThreeCenter = {
    //     position: 'relative',
    //     width: '35%',
    //     height: '80vh',
    //     textAlign:'center',
    //     boxShadow: 'rgb(0 0 0 / 10%) 1px 0px 5px 0px',
    // }

    // const columnThreeRight = {
    //     position: 'relative',
    //     width: '35%',
    //     height: '80vh',
    //     textAlign:'center',
    //     marginRight: '10%',
    //     boxShadow: 'rgb(0 0 0 / 10%) 1px 0px 5px 0px',
    // }

    const prodName = {
        marginTop: '10%',
        textTransform: 'uppercase',
        fontSize: '20px'
    }

    const prodCat = {
        marginTop: '2%',
        fontSize: '15px',
        fontWeight:'bold',
    }

    const colHalf = {
        position: 'relative',
        width: '60%',
        height: '60%',
        justifyContent: 'center',
        objectFit: 'contain'
    }

    const colTriple = {
        position: 'relative',
        width: '100%',
        height: '60%',
        justifyContent: 'center',
        marginTop:'5%',
        objectFit: 'contain'
    }

    const priceIs = {
        marginTop: '2%',
        fontSize: '18px',
        fontWeight:'300',
        color: '#000',
        width: '35%',
        transition: 'width 5s',
        cursor:'pointer',
        padding: '9px 25px',
        backgroundColor: '#efefec',
        borderBottomRightRadius: '25px',
        borderTopRightRadius: '25px',   
    }

    const buttonStyle = {
        marginTop: '5%',
        marginBottom: '5%',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '12em'
    }

    const [isHovering, setHovering] = useState({
        bedPrice: false,
        WardrobePrice: false,
        DelseaSofaPrice: false,
        ArmlessChairPrice: false,
        OttomanPrice: false,
        AriaArmChairPrice: false,
        SapphireSofaPrice: false,
    });

    const updateHovering = (productPrice, value) => {
        let existingValue = JSON.parse(JSON.stringify(isHovering));
        existingValue[productPrice] = value;
        setHovering(existingValue);
    }

    const handleClick = () => {
        window.location.href = 'https://asgharfurniture.ae/on-sale/';
    }

    return (
        <section className="customSection" id="product">
            <div className="rowSec">
                <h1 style={head}>MAKE YOUR HOUSEHOLD FEEL COZY WITH OUR <br/> FURNITURE AND DECORATIONS</h1>
            </div>
            <div className="rowSec">
                <div className="columnTwo firstcolumn">
                    <h2 style={prodName} className="products-heading">Glam Velvet Bed</h2>
                    <h2 style={prodCat}>Bedroom Furniture, MDF Elegent Bed</h2>
                    <img style={colHalf} src="GlamBed.png" alt="Bed Bedroom Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("bedPrice",true)} onMouseOut={() => updateHovering("bedPrice",false)}>
                            {isHovering.bedPrice ? "Add to Cart" : "1499.00 Dirhams"}
                        </p>
                    </div>
                </div>
                <div className="columnTwoRight firstcolumn">
                    <h2 style={prodName} className="products-heading">Delsea Modular Sofa</h2>
                    <h2 style={prodCat}>Living Room Furniture, Sectional Sofa / Sofa</h2>
                    <img style={colHalf} src="DelseaSofa.png" alt="Sectional Sofa Living Room Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("DelseaSofaPrice",true)} onMouseOut={() => updateHovering("DelseaSofaPrice",false)}>
                            {
                                isHovering.DelseaSofaPrice ? "Add to Cart" : "1749.00 Dirhams"
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowSec">
                <div className="columnThreeLeft">
                    <h2 style={prodName} className="products-heading">Armless 2-Seater Sofa</h2>
                    <h2 style={prodCat}>Living Room Furniture, Loveseats</h2>
                    <img style={colTriple} src="ArmlessChair.png" alt="Loveseats Living Room Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("ArmlessChairPrice", true)} onMouseOut={()=> updateHovering("ArmlessChairPrice", false)}>
                            {
                                isHovering.ArmlessChairPrice ? "Add to Cart" : "1099.00 Dirhams"
                            }
                        </p>
                    </div>
                </div>
                <div className="columnThreeCenter">
                    <h2 style={prodName} className="products-heading">4-Door Shoe Cabinet</h2>
                    <h2 style={prodCat}>Living Room Furniture, Oak Shoe Cabinets</h2>
                    <img style={colTriple} src="ShoeCabinet.png" alt="Cabinets Living Room Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("WardrobePrice", true)} onMouseOut={()=> updateHovering("WardrobePrice", false)}>
                            {
                                isHovering.WardrobePrice ? "Add to Cart" : "699.00 Dirhams"
                            }
                        </p>
                    </div>
                </div>
                <div className="columnThreeRight">
                    <h2 style={prodName} className="products-heading">Mirk Elite Accent Chair</h2>
                    <h2 style={prodCat}>Living Room Furniture, Loveseat Furniture</h2>
                    <img style={colTriple} src="MirkElite.png" alt="Wardrobe Living Room Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("OttomanPrice", true)} onMouseOut={()=> updateHovering("OttomanPrice", false)}>
                            {
                                isHovering.OttomanPrice ? "Add to Cart" : "649.00 Dirhams"
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowSec">
                <div className="columnTwo">
                    <h2 style={prodName} className="products-heading">Aria Arm Chair</h2>
                    <h2 style={prodCat}>Living Room Furniture, Arm Chairs / Accents</h2>
                    <img style={colHalf} src="AriaArmChair.png" alt="Accents Living Room Furniture"/>
                    <div>
                        <p style={priceIs} onClick={()=> handleClick()} onMouseOver={() => updateHovering("AriaArmChairPrice",true)} onMouseOut={() => updateHovering("AriaArmChairPrice",false)}>
                            {isHovering.AriaArmChairPrice ? "Add to Cart" : "1149.00 Dirhams"}
                        </p>
                    </div>
                </div>
                <div className="columnTwoRight">
                    <h2 style={prodName} className="products-heading">Delsea Modular Sofa</h2>
                    <h2 style={prodCat}>Living Room Furniture, Sectional Sofa / Sofa</h2>
                    <img style={colHalf} src="SapphireSofa.png" alt="Sectional Sofa Living Room Furniture"/>
                    <div>
                        <p style={priceIs} target="_blank" onClick={()=> handleClick()} onMouseOver={() => updateHovering("SapphireSofaPrice",true)} onMouseOut={() => updateHovering("SapphireSofaPrice",false)}>
                            {
                                isHovering.SapphireSofaPrice ? "Add to Cart" : "1749.00 Dirhams"
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowSec">
                <button style={buttonStyle} target="_blank" onClick={()=> handleClick()} className="alt">View All Products</button>
            </div>
        </section>
    );
}

export default ProductComponent;