import React from "react";

const AboutComponent = () =>{
    // const thuwalaStyle={
    //     height: '200vh',
    //     // backgroundColor: '#fafafa',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center center',
    // };

    // const row = {
    //     display: 'flex',
    //     columnCount: '2',
    // }

    // const lefttAbColumn = {
    //     width: '50%',
    //     backgroundColor: '#fafafa',
    //     height: '100vh',
    // }

    // const aboutImage = {
    //     height:'100%',
    //     width: '100%'
    // }

    // const abouttxtMaster = {
    //     position: 'relative',
    //     alignItems: 'center',
    //     height: '100vh',
    //     width: '50%',
    //     alignContent: 'space-between',

    // }

    // const rightText = {
    //     textAlign: 'left',
    //     marginTop: '10%',
    //     marginLeft: '10%',
    // }

    // const woodRight = {
    //     position: 'absolute',
    //     bottom:'0',
    //     marginLeft: '10%',
    //     marginBottom: '20%',
    //     minHeight: '7em',
    // }

    // const materialRight = {
    //     position: 'absolute',
    //     bottom:'0',
    //     marginLeft: '30%',
    //     marginBottom: '20%',
    //     minHeight: '7em',
    // }

    // const captionRight = {
    //     position: 'absolute',
    //     bottom:'0',
    //     marginLeft: '10%',
    //     marginBottom: '10%',
    //     textTransform: 'uppercase'
    // }

    const buttonElem = {
        position: 'absolute',
        marginLeft: '10%',
        marginTop: '5%',
    }

    const handleAboutClick = () => {
        window.location.href = "https://www.asgharfurniture.ae/about-us/";
    }

    return (
        <section className="thuwalaStyle" id="about">
            <div className="row first-section">
                <div className="lefttAbColumn">
                    <img src="AboutArmChair.png" className="aboutImage" alt="About Asghar Furniture"/>
                </div>
                <div className="abouttxtMaster">
                    <h2 className="rightText">We, Al AIN Furniture are best at <br/>choosing what our clients want. <br/>We make you guests love your interior</h2>
                    <p className="rightText">Serving over 20 years in the UAE from various region is our strength. <br/> To create a new standard of living and constitute a new nexus bringing <br/> luxury and affordability together in a decorous manner.</p>
                    <button className="alt" onClick = {() => handleAboutClick()} style={buttonElem} >View Mission</button>
                    <img className="woodRight" src="Wood.jpg" alt="Asghar Furniture Used Material Wood"/>
                    <img className="materialRight" src="Material.jpg" alt="Asghar Furniture Used Material Wood"/>
                    <p className="captionRight">* Best manufacturers choose best materials and woods. </p>
                </div>
            </div>
            <div className="row">
                <div className="abouttxtMaster">
                    <h2 className="rightText">The best UAE furnitures can be delivered<br/> to your door step.Al AIN Furniture is on<br/>  time when it comes to delivery</h2>
                    <p className="rightText">We serve our customers as the best possible way as we can. <br/> We will never make you wait until the good is delivered! Maximum 07 <br/> working days, you can avail your own customized furniture.</p>
                    <button className="alt" onClick = {() => handleAboutClick()} style={buttonElem} >View Mission</button>
                    <img className="woodRight" src="Wood.jpg" alt="Asghar Furniture Used Material Wood"/>
                    <img className="materialRight" src="SofaMaterial.png" alt="Asghar Furniture Used Material Wood"/>
                    <p className="captionRight">* Our classy elegent sofas are on offers! Hurry before it is late. </p>
                </div>
                <div className="lefttAbColumn">
                    <img src="AboutSofa.png" className="aboutImage" alt="About Asghar Furniture"/>
                </div>

            </div>
        </section>
    );
}

export default AboutComponent;