import React from "react";

const main = {
    backgroundColor: 'rgb(239, 239, 236)',
    paddingBottom: '5%',
    paddingTop:'5%'
}

// const sec = {
//     display: 'flex',
//     marginLeft: '5%',
//     marginRight: '5%',
// }

const headDsc = {
}

const contGet = {
    marginTop: '1%',
    marginBottom: '1%',
    fontSize: '50px',
    fontWeight: '800',
}

// const col4 = {
//     width: '25%',
//     alignItems: 'center',
// }

const SocialIcon = {
    marginRight: '10px',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
}

const socialMedia = (event) => {
    if(event.currentTarget.id === "facebookIcon"){
        window.location.href = "https://www.facebook.com/asgharfurnitureuae/";
    }else if(event.currentTarget.id === "instaIcon"){
        window.location.href = "https://www.instagram.com/asgharfurniture/";
    }else if(event.target.id === "twitterIcon"){
        window.location.href = "https://twitter.com/AsgharFurniture";
    }
}

const ContactComponent = () =>{
    return (
        <section style={main} id="contact">
            <div className="sec">
                <div className="col4">
                    <h2>Contact</h2>
                    <p style={headDsc}>Got any questions? We look forward<br/> to answering all your requests.</p>
                    <h1 style={contGet}>Get In<br/>Touch</h1>
                    <p style={headDsc}>Address: Al Ittihad Rd - Dubai<br/>Call On: +971 800 27 44 27</p>
                </div>
                <div className="col4">
                    <h2>Living Room</h2>
                    <a href="https://www.asgharfurniture.ae/living-room/sectional-sofa/" target="_blank" rel="noopener noreferrer" style={headDsc}>Sectional Sofa</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/center-table/" target="_blank" rel="noopener noreferrer" style={headDsc}>Center Table</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/daybed/" target="_blank" rel="noopener noreferrer" style={headDsc}>Daybed</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/sofa/" style={headDsc}>Sofa</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/accent-arm-chair/" target="_blank" rel="noopener noreferrer" style={headDsc}>Arm Chair</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/loveseat/" target="_blank" rel="noopener noreferrer" style={headDsc}>Loveseat</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/tv-cabinet/" target="_blank" rel="noopener noreferrer" style={headDsc}>TV Cabinet</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/chaise/" target="_blank" rel="noopener noreferrer" style={headDsc}>Chaise</a><br/>
                    <a href="https://www.asgharfurniture.ae/living-room/dining-set/" target="_blank" rel="noopener noreferrer" style={headDsc}>Dining</a><br/>
                </div>
                <div className="col4">
                    <h2>Bedroom</h2>
                    <a href="https://www.asgharfurniture.ae/bed-room/bed/" target="_blank" rel="noopener noreferrer" style={headDsc}>Bed</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/chest-of-drawer/" target="_blank" rel="noopener noreferrer" style={headDsc}>Chest of Drawer</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/computer-study-desk/" target="_blank" rel="noopener noreferrer" style={headDsc}>Computer Study Desk</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/dressing-table/" target="_blank" rel="noopener noreferrer" style={headDsc}>Dressing Table</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/nightstands/" target="_blank" rel="noopener noreferrer" style={headDsc}>Nightstand</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/ottoman/" target="_blank" rel="noopener noreferrer" style={headDsc}>Ottoman</a><br/>
                    <a href="https://www.asgharfurniture.ae/bed-room/wardrobe/" target="_blank" rel="noopener noreferrer" style={headDsc}>Wardrobe</a><br/>
                    <a href="https://www.asgharfurniture.ae/storage-furniture/storage-cabinet/" target="_blank" rel="noopener noreferrer" style={headDsc}>Storage Cabinet</a><br/>
                    <a href="https://www.asgharfurniture.ae/storage-furniture/shoe-cabinet/" target="_blank" rel="noopener noreferrer" style={headDsc}>Shoe Cabinet</a><br/>
                </div>
                <div className="col4">
                    <h2>Follow on</h2>
                    <img style={SocialIcon} id="facebookIcon" onClick={(event) => socialMedia(event)} src="FaceBookIcon.png" alt="Instagram Asghar Furniture"/>
                    <img style={SocialIcon} id="instaIcon" onClick={(event) => socialMedia(event)} src="InstaIcon.png" alt="Instagram Asghar Furniture"/>
                    <img style={SocialIcon} id="twitterIcon" onClick={(event) => socialMedia(event)} src="TwitterIcon.png" alt="Instagram Asghar Furniture"/>
                    <h1 style={contGet}>VISIT By</h1>
                    <p style={headDsc}>Weekdays: 09:00 AM - 10:00 PM<br/>Weekends: 04:00 PM - 10:00 PM</p>
                </div>
            </div>

        </section>
    );
}

export default ContactComponent;