import './App.css';
import React from 'react';
// import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//COMPONENT
import NavigationComponent from './Component/NavigationComponent';
// import ServicesComponent from '../HomeComponents/Services';


function App() {
    return (
        // <Router>
        //     <div className="App">
        //         <NavigationComponent/>
        //         <Routes>
        //             <Route path="/" element={<SliderComponent/>} exact/>
        //             <Route path="/about" element={<AboutComponent/>} exact/>
        //             <Route path="/product" element={<ProductComponent/>} exact/>
        //             <Route path="/contact" element={<ContactComponent/>} exact/>
        //         </Routes>
        //     </div>
        // </Router>
        <NavigationComponent/>

    );
}

export default App;
