import React from 'react';

const SliderComponent = () => {

    const thuwalaStyle={
        backgroundImage: "url('sliderBackground.jpg')",
        height: '90vh',
        marginTop: '5px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    };

    const row = {
        display: 'flex',
        columnCount: '2'
    };
    
    const column = {
        width: '50%',
        height:'80vh',
        marginLeft:'10px',
        position: 'relative',

    };

    const columnElem = {
        marginTop: '30%',
        marginLeft: '10%'
    }

    const buttonElem = {
        marginLeft: '1px',
        marginTop: '10px',
    }

    const paraElement = {
        position: 'absolute',
        bottom: '0',
    }

    const rightColumnElem = {
        position: 'relative',
        marginTop: '30%',
        right: '10%',
        textAlign: 'right'
    }

    // const iconStyle = {
    //     width: '35px',
    //     height: '35px',
    //     marginRight:'10px',
    //     marginTop:'10px',
    //     cursor: 'pointer'
    // }

    // const arrowElem = {
    //     position: 'absolute',
    //     right: '15px',
    //     fontSize:'45px',
    // }

    // const rightParaElement = {
    //     position: 'absolute',
    //     marginTop: '15%',
    //     right: '-3%',
    //     WebkitTransform: 'rotate(-90deg)',
    // }

    const handleClick = () => {
        window.location.href = "https://www.asgharfurniture.ae/living-room/accent-arm-chair/sabrina-velvet-armchair/";
    }

    const socialMediaClick = (event) =>{
        if(event.target.id === "instaIcon"){
            window.location.href = "https://www.instagram.com/asgharfurniture/";
        }else if(event.target.id === "facebookIcon"){
            window.location.href = "https://www.facebook.com/asgharfurnitureuae/";
        }else if(event.target.id === "twitterIcon"){
            window.location.href = "https://twitter.com/AsgharFurniture";
        }
    }

    return(
        <div style={thuwalaStyle} className='top-banner'>
            <div style={row}>
                <div style={column}>
                    <div style={columnElem} className='banner-left'>
                        <h1>Sabrina Velvet</h1>
                        <h1>Arm Chair</h1>
                        <button onClick={() => handleClick()} style={buttonElem} className="alt" >View Chair</button>
                        <p style={paraElement}>Hurry to collect your own! Only few pieces are left!</p>
                    </div>
                </div>
                <div style={column}>
                    <div style={rightColumnElem} className='banner-right'>
                        <p> We are a one of the best and leading <br></br>furniture store in UAE </p>
                        <div className='iconSet'>
                            <img id="instaIcon" src="InstaIcon.png" onClick={(event) => socialMediaClick(event)}  className="iconStyle" alt="Follow us on Instagram"/><br/>
                            <img id="facebookIcon" src="FaceBookIcon.png" onClick={(event) =>socialMediaClick(event)} className="iconStyle" alt="Follow us on Facebook"/><br/>
                            <img id="twitterIcon" src="TwitterIcon.png"  onClick={(event) => socialMediaClick(event)} className="iconStyle" alt="Follow us on Twitter" /><br/>
                        </div>
                        <h3 className='arrowElem'>↑</h3>
                        <h3 className="rightParaElement">Follow Us At</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderComponent;