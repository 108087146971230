import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';

import SliderComponent from './SliderComponent';
import AboutComponent from './AboutComponent';
import ProductComponent from './ProductComponent';
import ContactComponent from './ContactComponent';

const NavigationComponent = () => {

    return(
        <BrowserRouter>
            <header className=".site-header">
                <Link className="logo" to="/">
                    <img src="Al AINFurnitureMini.png" alt="Al AIN Furniture Logo" />
                </Link>
                <nav>
                <input type="checkbox" id="check"/>
                <label className="checkbtn">
                    <i className="fas fa-bars"></i>
                </label>
                    <ul className="navLinks">
                        <li>
                            <Link to="/">Base</Link>
                        </li>
                        <li>
                            {/* <Link to="/about">Who we Are?</Link> */}
                            <Link to='#about' smooth>Who we Are?</Link>
                        </li>
                        <li>
                            <Link to='#product' smooth>eShowroom</Link>
                        </li>
                    </ul>
                </nav>
                <Link to='#contact' smooth>
                    <button className="alt">Contact Us</button>
                </Link>
            </header>
            <SliderComponent/>
            <AboutComponent/>
            <ProductComponent/>
            <ContactComponent/>
        </BrowserRouter>

    );
}

export default NavigationComponent;